<template>
  <div class="text-center" v-if="loading">
    <v-progress-circular
      :size="80"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <div v-else style="padding-top: 15px">
    <input type="file" ref="file" style="display: none" @change="onPickFile" />
    <add-user v-model="addUserModal" @createUser="createUser" @close="addUserModal = false" />
    <v-row>
      <v-col cols="10" offset="1" style="display: flex; align-items: center">
        <v-btn @click="addUserModal = true">ADD USER</v-btn></v-col
      >
    </v-row>
    <v-row>
      <v-col md="9">
        <v-card max-width="1100" tile class="carMenu">
          <!-- <v-text-field
          style="padding: 15px 10px 0 10px"
          v-model="searchQuery"
          :label="$t('searchUsers')"
          append-icon="mdi-magnify"
          clearable
          :loading="loadingSearch"
          @click:append="search"
          @click:clear="clear"
          @keyup.enter="search"
        ></v-text-field> -->
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Email</th>
                  <th class="text-left">Package</th>
                  <th class="text-left">Expires at</th>
                  <th class="text-left">BG credits</th>
                  <th class="text-left">Spins left</th>
                  <th class="text-left">Custom backgrounds</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in users"
                  :key="user.email"
                  @click="user.editing = !user.editing"
                >
                  <template v-if="user.editing">
                    <td>
                      {{ user.email }}
                    </td>
                    <td>
                      <v-select
                        v-model="user.package"
                        @click.stop
                        :items="['BUSINESS', 'BUSINESS+']"
                      ></v-select>
                    </td>
                    <td>
                      <v-menu
                        v-model="user.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="user.datePicker"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            style="width: 130px"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="user.datePicker"
                          @input="user.menu = false"
                          @change="setExpirationTime(user)"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        @click.stop
                        v-model.number="user.backgroundChangeCredits"
                      ></v-text-field>
                    </td>

                    <td>
                      <v-text-field
                        @click.stop
                        v-model.number="user.spinsLeft"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        style="margin-left: 10px"
                        tile
                        @click.stop="uploadBackground(user)"
                        >Upload</v-btn
                      >
                    </td>
                    <td>
                      <v-btn tile @click.stop="updateUser(user)">UPDATE</v-btn>
                    </td>
                  </template>
                  <template v-else>
                    <td>{{ user.email }}</td>
                    <td>{{ user.package }}</td>
                    <td>{{ new Date(user.expiresAt).toLocaleDateString() }}</td>
                    <td>{{ user.backgroundChangeCredits }}</td>
                    <td>
                      {{ user.spinsLeft }}
                    </td>
                    <td>({{ user.backgrounds.length }})</td>
                    <td></td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="3" style="padding-top: 0">
        <v-card v-if="removeBgStats" elevation="2" style="margin: 10px">
          <v-card-title
            >REMOVE.BG CREDITS:
            {{ removeBgStats.attributes.credits.total }}</v-card-title
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  getUsers,
  searchSpins,
  uploadBackground,
  updateUser,
  getRemoveBgAccStats,
  addUser,
} from "@/api";
import AddUser from './AddUser.vue';

export default {
  components: {
    AddUser
    // Preview,
  },
  data: () => ({
    users: [],
    selectedUser: null,
    loading: false,
    searchQuery: "",
    loadingSearch: false,
    removeBgStats: null,
    addUserModal: false,
  }),
  created() {
    this.fetchUsers();
    this.getRemoveBgAccStats();
  },
  mounted() {},
  methods: {
    async getRemoveBgAccStats() {
      const res = await getRemoveBgAccStats();
      this.removeBgStats = res.data;
    },
    async fetchUsers() {
      this.loading = true;
      const users = await getUsers();
      this.users = users.map((u) => ({
        ...u,
        editing: false,
        menu: false,
        datePicker: new Date(u.expiresAt).toISOString().substr(0, 10),
      }));
      this.loading = false;
    },
    async setExpirationTime(user) {
      user.expiresAt = new Date(user.datePicker).valueOf();
    },
    async updateUser(user) {
      const {
        backgroundChangeCredits,
        expiresAt,
        spinsLeft,
        customBackgrounds,
      } = user;
      const obj = {
        package: user.package,
        backgroundChangeCredits,
        expiresAt,
        spinsLeft,
        customBackgrounds: customBackgrounds || [],
        userId: user._id,
      };
      await updateUser(obj);
      user.editing = false;
    },
    async uploadBackground(user) {
      this.selectedUser = user;
      this.$refs.file.click();
    },
    async onPickFile(event) {
      const images = event.target.files;

      let formData = new FormData();

      formData.append("file", images[0], images[0].name);
      formData.append("spinId", this.selectedUser._id);
      formData.append("userId", this.selectedUser._id);

      await uploadBackground(formData);

      const backgrounds = this.selectedUser.backgrounds
        ? [...this.selectedUser.backgrounds, images[0].name]
        : [images[0].name];
      const obj = {
        userId: this.selectedUser._id,
        backgrounds,
      };
      await updateUser(obj);
      this.fetchUsers()
    },
    async createUser(user) {
      await addUser(user)
      this.fetchUsers()
      this.addUserModal = false
    },
    async search() {
      if (!this.searchQuery.trim()) return;
      this.loadingSearch = true;
      this.users = await searchSpins(this.searchQuery);
      this.loadingSearch = false;
    },
    async clear() {
      this.searchQuery = "";
      this.loadingSearch = true;
      this.users = await getUsers();
      this.loadingSearch = false;
    },
  },
};
</script>

<style>
.carMenu {
  margin: 0 auto 40px auto;
}

.carMenu .v-list-item:hover i {
  color: #1a76d2;
}

.carMenu .v-list-item__title {
  font-size: 1.125em !important;
  text-transform: capitalize;
  font-weight: 400 !important;
  line-height: 1.5em !important;
}
.carMenu .v-btn--round {
  margin-top: -5px;
}
.carMenu .v-list--dense .v-input {
  height: 40px;
  padding-top: 0;
}

.title_list {
  color: #1a76d2 !important;
  max-width: 687px;
  margin: 27px auto 10px auto;
  padding: 0 0 0 15px;
}

.carMenu .v-label {
  font-size: 1.125em !important;
}
.carMenu .col-12 {
  padding: 6px 17px 4px 17px;
}
</style>
