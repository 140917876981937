<template>
  <v-dialog v-model="show" @click:outside="$emit('close')" width="350">
    <v-form>
      <v-card class="mx-auto createHotspot" width="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <v-text-field
              v-model="user.email"
              placeholder="Email"
              outlined
              required
            ></v-text-field>

            <v-text-field
              v-model="user.password"
              placeholder="Password"
              type="password"
              outlined
              required
            ></v-text-field>

            <v-text-field
              v-model.number="user.spinsLeft"
              placeholder="Spins"
              outlined
              required
              type="number"
            ></v-text-field>

            <v-text-field
              v-model.number="user.backgroundChangeCredits"
              placeholder="Background changes"
              outlined
              required
              type="number"
            ></v-text-field>

            <v-select
              v-model="user.package"
              :items="packages"
              label="Package"
              filled
            ></v-select>

            <v-menu
              v-model="user.menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user.datePicker"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  style="width: 130px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="user.datePicker"
                @input="user.menu = false"
                @change="setExpirationTime(user)"
              ></v-date-picker>
            </v-menu>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn color="primary" rounded block @click="createUser">
            CREATE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  name: "AddUser",
  components: {},
  mixins: [],
  props: ["value"],
  data: () => ({
    packages: ["BUSINESS", "BUSINESS+"],
    user: {
      email: "",
      password: "",
      spinsLeft: 0,
      backgroundChangeCredits: 0,
      package: null,
      expiresAt: null,
      datePicker: null,
      menu: false,
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    setExpirationTime(user) {
      user.expiresAt = new Date(this.user.datePicker).valueOf();
    },
    createUser() {
      const user = { ...this.user };
      delete user.menu;
      delete user.datePicker;
      this.$emit('createUser', user)
    },
  },
};
</script>

<style>
.createHotspot {
  padding-bottom: 10px;
}

.createHotspot .v-list-item__content > .v-image {
  border-radius: 5px;
  margin-top: 10px;
}
.createHotspot .v-list-item__content > .v-input {
  height: 65px;
  margin-top: 10px;
}
.createHotspot .v-list-item__content > .v-textarea {
  height: 75px;
  margin-top: 0;
}
.createHotspot .v-list-item__content > div:last-of-type {
  height: 65px;
  margin-top: 0;
}

.createHotspot .v-card__actions > button {
  font-size: 1em;
  font-weight: bold;
  padding: 20px 0;
}
</style>
