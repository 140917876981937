<template>
  <user-list />
</template>
<script>
import UserList from "@/components/admin/UserList";

export default {
  name: "Admin",
  components: { UserList },
};
</script>